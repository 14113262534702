import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { SnackbarContent, SnackbarKey, useSnackbar } from "notistack";
import { forwardRef } from "react";

import { Message } from "typings/Message";

type MessageContentProps = {
  message: Message;
  messageKey: SnackbarKey;
};

const Action = ({ message, messageKey }: MessageContentProps) => {
  const { closeSnackbar } = useSnackbar();

  const { action, persist } = message;

  return (
    <Stack
      direction="row"
      onClick={() => (action ? closeSnackbar(messageKey) : null)}
      sx={{
        gap: 1,
        paddingLeft: 1,
      }}
    >
      {action && action}
      {!persist && (
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => closeSnackbar(messageKey)}
          size="small"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </Stack>
  );
};

export const MessageContent = forwardRef<HTMLDivElement, MessageContentProps>(
  ({ message, messageKey }, ref) => {
    const { text, variant } = message;

    return (
      <SnackbarContent ref={ref}>
        <Alert
          action={<Action message={message} messageKey={messageKey} />}
          severity={variant}
          sx={{
            width: "100%",
          }}
          variant="standard"
        >
          {text}
        </Alert>
      </SnackbarContent>
    );
  },
);

MessageContent.displayName = "Driven::MessageContent";

export default MessageContent;
