import { useCallback, useEffect } from "react";
import { useResolve } from "react-jpex";
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";

import {
  ACTIVITY,
  ADMIN_CONCIERGES,
  ADMIN_LAYOUT_SCOPES,
  ADMIN_LISTINGS,
  ADMIN_USAGE_HISTORY,
  ADMIN_USERS,
  ADMIN_VEHICLES,
  COLLECTION,
  COLLECTOR,
  COOKIE_POLICY,
  DASHBOARD,
  DATA_ROOM,
  DOCUMENT,
  GALLERY,
  HELP_CENTER,
  HOMEPAGE,
  LOCATION,
  LOGBOOK,
  LOGIN,
  LOGOUT,
  MESSAGES,
  NOT_FOUND,
  ONBOARDING,
  ORGANIZATION_COLLECTOR,
  ORGANIZATION_DATA_ROOM,
  ORGANIZATION_DEALERSHIP,
  ORGANIZATION_LOCATION,
  ORGANIZATION_REQUEST,
  ORGANIZATION_SERVICE,
  ORGANIZATION_USAGE_HISTORY,
  ORGANIZATION_USER_MANAGEMENT,
  ORGANIZATION_VEHICLE,
  PRIVACY_POLICY,
  REMINDERS,
  RESET_PASSWORD,
  SANDBOX,
  SERVICE_REQUEST,
  SETTINGS,
  SETTINGS_COLLABORATION,
  SETTINGS_ENCRYPTION,
  SETTINGS_EXPERIMENTS,
  SETTINGS_PROFILE,
  SETTINGS_SECURITY,
  SETTINGS_SUBSCRIPTION,
  SETTINGS_SUBSCRIPTION_INVOICES,
  SETTINGS_SUBSCRIPTION_PAYMENT_METHODS,
  SETTINGS_TAG_MANAGER,
  SIGNUP,
  SUBSCRIBE,
  SUBSCRIBE_BILLING,
  TERMS_CONDITIONS,
  TRANSACTIONS,
  TRANSACTIONS_MY_DATA_ROOMS,
  TRANSACTIONS_NEGOTIATIONS,
  VAULT,
  VEHICLE,
  WISHLIST,
} from "constants/routes";
import { AuthenticationService } from "typings/AuthenticationService";
import { Collection } from "typings/Collection";
import { Collector } from "typings/Collector";
import {
  DataRoom,
  DataRoomMembership,
  DataRoomMessage,
} from "typings/DataRoom";
import { ExperimentalFeatures } from "typings/ExperimentalFeatures";
import { HistoryRecord } from "typings/HistoryRecord";
import { Listing } from "typings/Listing";
import { Location } from "typings/Location";
import { Media } from "typings/Media";
import { Negotiation } from "typings/Negotiation";
import { ServiceRequest } from "typings/ServiceRequest";
import { MessageOnNavigation } from "typings/Topics";
import { Vehicle } from "typings/Vehicle";
import { Wishlist } from "typings/Wishlist";

export const usePublishMessageOnNavigation = () => {
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.message) {
      return;
    }

    const { data, message } = state.message as MessageOnNavigation;

    if (message) {
      PubSub.publish(message, data);
    }
  }, [state]);
};

export const useNavigation = (optionsParam?: NavigateOptions) => {
  const auth = useResolve<AuthenticationService>("AuthenticationService");
  const isAuthenticated = useCallback(() => auth.isAuthenticated(), [auth]);
  const isAdmin = useCallback(() => auth.isAdmin(), [auth]);
  const hasOrganizationPrivileges = useCallback(
    () => auth.hasOrganizationPrivileges(),
    [auth],
  );

  const nav = useNavigate();
  const { pathname } = useLocation();
  const { collectorId, dataRoomId, vehicleId: vehicleIdParam } = useParams();
  const isInDataRoomContext = dataRoomId && !vehicleIdParam;

  const navigate = (
    to: To,
    message?: MessageOnNavigation,
    optionsOverride?: NavigateOptions,
  ) => {
    const options =
      optionsParam || optionsOverride
        ? {
            ...optionsParam,
            ...optionsOverride,
          }
        : undefined;

    if (message) {
      nav(to, {
        ...options,
        ...(message ? { state: { message } } : undefined),
      });
    } else {
      nav(to, options);
    }
  };

  const navigateGarage = (to: To, message?: MessageOnNavigation) => {
    if (collectorId) {
      navigate(`${ORGANIZATION_COLLECTOR}/${collectorId}${to}`, message);
    } else {
      navigate(to, message);
    }
  };

  const navigateDataRoom = (to: To, message?: MessageOnNavigation) => {
    navigate(
      `${hasOrganizationPrivileges() ? ORGANIZATION_DATA_ROOM : DATA_ROOM}${to}`,
      message,
    );
  };

  const goToCurrent = () => navigate(pathname);

  const goToNotFound = () => navigate(NOT_FOUND, undefined, { replace: true });

  const goToRoot = () => navigate("/");
  const goToHome = () => {
    window.location.href = HOMEPAGE ?? "/";
  };
  const goToHelp = () => {
    window.open(HELP_CENTER ?? "/", "_blank", "noreferrer");
  };
  const goToTermsAndConditions = () => {
    window.open(TERMS_CONDITIONS ?? "/", "_blank", "noreferrer");
  };
  const goToPrivacyPolicy = () => {
    window.open(PRIVACY_POLICY ?? "/", "_blank", "noreferrer");
  };
  const goToCookiePolicy = () => {
    window.open(COOKIE_POLICY ?? "/", "_blank", "noreferrer");
  };

  const goToOrganizationCollectors = () => navigate(ORGANIZATION_COLLECTOR);
  const goToOrganizationCollectorsGarage = (id: Collector["id"]) =>
    navigate(`${ORGANIZATION_COLLECTOR}/${id}${VEHICLE}`);
  const goToOrganizationLocation = (id: Location["id"]) =>
    navigate(`${ORGANIZATION_LOCATION}/${id}`);
  const goToOrganizationRequests = (serviceRequestId?: ServiceRequest["id"]) =>
    navigate(`${ORGANIZATION_REQUEST}/${serviceRequestId ?? ""}`);

  const goToOrganizationDataRooms = () => navigate(ORGANIZATION_DATA_ROOM);
  const goToOrganizationDealerships = () => navigate(ORGANIZATION_DEALERSHIP);

  const goToOrganizationUserManagement = () =>
    navigate(ORGANIZATION_USER_MANAGEMENT);
  const goToOrganizationLocations = () => navigate(ORGANIZATION_LOCATION);
  const goToOrganizationUsageHistory = () =>
    navigate(ORGANIZATION_USAGE_HISTORY);
  const goToOrganizationServices = () => navigate(ORGANIZATION_SERVICE);

  const goToOrganizationVehicles = () => navigate(ORGANIZATION_VEHICLE);

  const goToDashboard = () => navigateGarage(DASHBOARD);

  const goToSettings = () => navigate(SETTINGS);
  const goToSettingsCollaboration = () => navigate(SETTINGS_COLLABORATION);
  const goToSettingsEncryption = () => navigate(SETTINGS_ENCRYPTION);
  const goToSettingsExperiments = (highlighted?: keyof ExperimentalFeatures) =>
    navigate(`${SETTINGS_EXPERIMENTS}/${highlighted ?? ""}`);
  const goToSettingsProfile = () => navigate(SETTINGS_PROFILE);
  const goToSettingsProfileAvatar = () =>
    navigate(`${SETTINGS_PROFILE}/avatar`);
  const goToSettingsTagManager = () => navigate(SETTINGS_TAG_MANAGER);
  const goToSettingsSecurity = () => navigate(SETTINGS_SECURITY);
  const goToSettingsSubscription = () => navigate(SETTINGS_SUBSCRIPTION);
  const goToSettingsSubscriptionInvoices = () =>
    navigate(SETTINGS_SUBSCRIPTION_INVOICES);
  const goToSettingsSubscriptionPaymentMethods = () =>
    navigate(SETTINGS_SUBSCRIPTION_PAYMENT_METHODS);

  const goToCollectorVehicle = (id: Collector["id"]) =>
    hasOrganizationPrivileges()
      ? navigate(`${ORGANIZATION_COLLECTOR}/${id}${VEHICLE}`)
      : navigate(`${COLLECTOR}/${id}${VEHICLE}`);
  const goToCollectorCollections = (id: Collector["id"]) =>
    navigate(`${COLLECTOR}/${id}${COLLECTION}`);
  const goToCollectorLocations = (id: Collector["id"]) =>
    navigate(`${COLLECTOR}/${id}${LOCATION}`);
  const goToCollectorLogbook = (id: Collector["id"]) =>
    navigate(`${COLLECTOR}/${id}${LOGBOOK}`);

  const goToVehicle = () => navigateGarage(VEHICLE);

  const goToVehicleDataRooms = (
    vehicleId: Vehicle["id"],
    ownerId: Collector["id"],
  ) =>
    hasOrganizationPrivileges()
      ? navigate(
          `${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}${DATA_ROOM}`,
        )
      : navigateGarage(`${VEHICLE}/${vehicleId}${DATA_ROOM}`);

  const goToVehicleDataRoom = (
    vehicleId: Vehicle["id"],
    dataRoomId: DataRoom["id"],
    ownerId: Collector["id"],
  ) =>
    hasOrganizationPrivileges()
      ? navigate(
          `${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}${DATA_ROOM}/${dataRoomId}`,
        )
      : navigateGarage(`${VEHICLE}/${vehicleId}${DATA_ROOM}/${dataRoomId}`);

  const goToVehicleDataRoomActivity = (
    vehicleId: Vehicle["id"],
    dataRoomId: DataRoom["id"],
    ownerId: Collector["id"],
  ) => {
    const path = `${VEHICLE}/${vehicleId}${DATA_ROOM}/${dataRoomId}${ACTIVITY}`;
    if (hasOrganizationPrivileges()) {
      navigate(`${ORGANIZATION_COLLECTOR}/${ownerId}${path}`);
    } else {
      navigateGarage(path);
    }
  };

  const goToVehicleDataRoomMessage = (
    vehicleId: Vehicle["id"],
    dataRoomId: DataRoom["id"],
    ownerId: Collector["id"],
    memberId?: DataRoomMessage["memberId"],
  ) => {
    const path = `${VEHICLE}/${vehicleId}${DATA_ROOM}/${dataRoomId}${MESSAGES}${memberId ? `/${memberId}` : ""}`;

    if (hasOrganizationPrivileges()) {
      navigate(`${ORGANIZATION_COLLECTOR}/${ownerId}${path}`);
    } else {
      navigateGarage(path);
    }
  };

  const goToVehicleGallery = (
    vehicleId: Vehicle["id"],
    ownerId: Collector["id"],
  ) =>
    hasOrganizationPrivileges()
      ? navigate(
          `${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}${GALLERY}`,
        )
      : navigateGarage(`${VEHICLE}/${vehicleId}${GALLERY}`);

  const goToVehicleLogbook = (
    vehicleId: Vehicle["id"],
    ownerId: Collector["id"],
  ) => {
    if (isInDataRoomContext) {
      return goToDataRoomLogbook(dataRoomId);
    }

    return hasOrganizationPrivileges()
      ? navigate(
          `${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}${LOGBOOK}`,
        )
      : navigateGarage(`${VEHICLE}/${vehicleId}${LOGBOOK}`);
  };

  const goToVehicleLogbookOverview = (
    vehicleId: Vehicle["id"],
    recordId?: HistoryRecord["id"],
    ownerId?: Collector["id"],
    message?: MessageOnNavigation<HistoryRecord["id"]>,
  ) => {
    if (isInDataRoomContext) {
      return goToDataRoomLogbookOverview(dataRoomId, recordId, message);
    }

    return hasOrganizationPrivileges()
      ? navigate(
          `${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}${LOGBOOK}/${recordId}`,
        )
      : navigateGarage(
          `${VEHICLE}/${vehicleId}${LOGBOOK}/${recordId}`,
          message,
        );
  };

  const goToVehicleOverview = (
    vehicleId: Vehicle["id"],
    ownerId?: Collector["id"],
    message?: MessageOnNavigation<Vehicle["id"]>,
  ) => {
    if (isInDataRoomContext) {
      return goToDataRoom(dataRoomId);
    }

    if (hasOrganizationPrivileges()) {
      navigate(`${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}`);
    } else {
      navigateGarage(`${VEHICLE}/${vehicleId}`, message);
    }
  };

  const goToVehicleRequests = (
    vehicleId: Vehicle["id"],
    ownerId?: Collector["id"],
    serviceRequestId?: ServiceRequest["id"],
  ) =>
    hasOrganizationPrivileges()
      ? navigate(
          `${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}/service-request/${
            serviceRequestId ?? ""
          }`,
        )
      : navigateGarage(
          `${VEHICLE}/${vehicleId}/service-request/${serviceRequestId ?? ""}`,
        );

  const goToVehicleVault = (
    vehicleId: Vehicle["id"],
    ownerId?: Collector["id"],
  ) =>
    hasOrganizationPrivileges()
      ? navigate(
          `${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}/vault`,
        )
      : navigateGarage(`${VEHICLE}/${vehicleId}/vault`);

  const goToCollections = () => navigateGarage(COLLECTION);

  const goToCollectionOverview = (
    collectionId: Collection["id"],
    message?: MessageOnNavigation<Collection["id"]>,
  ) => navigateGarage(`${COLLECTION}/${collectionId}`, message);

  const goToLocations = () => navigateGarage(LOCATION);

  const goToLocationGallery = (
    locationId: Location["id"],
    message?: MessageOnNavigation<Location["id"]>,
  ) =>
    hasOrganizationPrivileges()
      ? navigate(`${ORGANIZATION_LOCATION}/${locationId}/gallery`)
      : navigateGarage(`${LOCATION}/${locationId}/gallery`, message);

  const goToLocationVehicles = (locationId: Location["id"]) =>
    hasOrganizationPrivileges()
      ? navigate(`${ORGANIZATION_LOCATION}/${locationId}/vehicles`)
      : navigateGarage(`${LOCATION}/${locationId}/vehicles`);

  const goToLocationOverview = (
    locationId: Location["id"],
    message?: MessageOnNavigation<Location["id"]>,
  ) =>
    hasOrganizationPrivileges()
      ? navigate(`${ORGANIZATION_LOCATION}/${locationId}`)
      : navigateGarage(`${LOCATION}/${locationId}`, message);

  const goToActivity = () => navigateGarage(ACTIVITY);

  const goToDataRoom = (dataRoomId: DataRoom["id"]) =>
    navigateDataRoom(`/${dataRoomId}`);

  const goToDataRoomGallery = (dataRoomId: DataRoom["id"]) =>
    navigateDataRoom(`/${dataRoomId}/gallery`);

  const goToDataRoomLogbook = (dataRoomId: DataRoom["id"]) =>
    navigateDataRoom(`/${dataRoomId}/logbook`);

  const goToDataRoomLogbookOverview = (
    dataRoomId: DataRoom["id"],
    recordId?: HistoryRecord["id"],
    message?: MessageOnNavigation<HistoryRecord["id"]>,
  ) => navigateDataRoom(`/${dataRoomId}/logbook/${recordId}`, message);

  const goToDataRoomVault = (dataRoomId: DataRoom["id"]) =>
    navigateDataRoom(`/${dataRoomId}/vault`);

  const goToDataRoomMessages = (dataRoomId: DataRoom["id"]) =>
    navigateDataRoom(`/${dataRoomId}/messages`);

  const goToDataRooms = () => navigateGarage(DATA_ROOM);

  const goToLogbook = () => navigateGarage(LOGBOOK);

  const goToReminders = () => navigateGarage(REMINDERS);

  const goToRequests = () => navigateGarage(SERVICE_REQUEST);

  const goToRequestsMessages = (serviceRequestId: ServiceRequest["id"]) =>
    hasOrganizationPrivileges()
      ? navigate(`${ORGANIZATION_REQUEST}/${serviceRequestId}/messages`)
      : navigateGarage(`${SERVICE_REQUEST}/${serviceRequestId}/messages`);

  const goToLogbookOverview = (
    vehicleId: Vehicle["id"],
    historyRecordId: HistoryRecord["id"],
    ownerId: Collector["id"],
    message?: MessageOnNavigation<HistoryRecord["id"]>,
  ) => {
    if (isInDataRoomContext) {
      return goToDataRoomLogbookOverview(dataRoomId, historyRecordId, message);
    }

    return hasOrganizationPrivileges()
      ? navigate(
          `${ORGANIZATION_COLLECTOR}/${ownerId}${VEHICLE}/${vehicleId}${LOGBOOK}/${historyRecordId}`,
        )
      : navigateGarage(
          `${VEHICLE}/${vehicleId}${LOGBOOK}/${historyRecordId}`,
          message,
        );
  };

  const goToVault = () => navigateGarage(VAULT);

  const goToWishlist = (id?: Wishlist["id"]) =>
    navigateGarage(id ? `${WISHLIST}/${id}` : WISHLIST);

  const goToLogin = () => navigate(LOGIN);
  const goToLogout = () => navigate(LOGOUT);
  const goToSignup = () => navigate(SIGNUP);
  const goToResetPassword = () => navigate(RESET_PASSWORD);

  const goToOnboarding = () => navigate(ONBOARDING);

  const goToAdminLayoutScopes = () => navigate(ADMIN_LAYOUT_SCOPES);
  const goToAdminListings = () => navigate(ADMIN_LISTINGS);
  const goToAdminConcierges = (id?: Collector["id"]) =>
    navigate(id ? `${ADMIN_CONCIERGES}/${id}` : ADMIN_CONCIERGES);
  const goToAdminUsageHistory = () => navigate(ADMIN_USAGE_HISTORY);
  const goToAdminUsers = (id?: Collector["id"]) =>
    navigate(id ? `${ADMIN_USERS}/${id}` : ADMIN_USERS);
  const goToAdminVehicles = (id?: Vehicle["id"]) =>
    navigate(id ? `${ADMIN_VEHICLES}/${id}` : ADMIN_VEHICLES);

  const getVaultFileLink = ({ id }: Media) =>
    `${window.location.protocol}//${window.location.host}${DOCUMENT}/${id}`;

  const getVehicleOverviewLink = (id: Vehicle["id"]) =>
    `${window.location.protocol}//${window.location.host}${VEHICLE}/${id}`;

  const getCollectionOverviewLink = (id: Vehicle["id"]) =>
    `${window.location.protocol}//${window.location.host}${COLLECTION}/${id}`;

  const getDataRoomMembershipInviteLink = (
    token: DataRoomMembership["token"],
  ) =>
    `${window.location.protocol}//${window.location.host}${DATA_ROOM}?dataRoom=${token}`;

  const getLocationOverviewLink = (id: Location["id"]) =>
    `${window.location.protocol}//${window.location.host}${LOCATION}/${id}`;

  const getGarageVehicleOverviewLink = (id: Vehicle["id"]) =>
    `${window.location.protocol}//${window.location.host}${VEHICLE}/${id}`;

  const getGarageCollectionOverviewLink = (id: Collection["id"]) =>
    `${window.location.protocol}//${window.location.host}${COLLECTION}/${id}`;

  const getGarageLocationOverviewLink = (id: Location["id"]) =>
    `${window.location.protocol}//${window.location.host}${LOCATION}/${id}`;

  const getCollaborationInviteLink = (token: string) =>
    `${window.location.protocol}//${window.location.host}${SETTINGS_COLLABORATION}?token=${token}`;

  const getTransactionsListingPath = (id: Listing["id"]) =>
    `${TRANSACTIONS_MY_DATA_ROOMS}/${id}`;

  const getTransactionsListingDataRoomPath = (id: Listing["id"]) =>
    `${TRANSACTIONS_MY_DATA_ROOMS}/${id}${SETTINGS}`;

  const getTransactionsListingNegotiationPath = (id: Listing["id"]) =>
    `${TRANSACTIONS_NEGOTIATIONS}/${id}`;

  const getTransactionsListingNegotiationDataRoomPath = (id: Listing["id"]) =>
    `${TRANSACTIONS_NEGOTIATIONS}/${id}${DATA_ROOM}`;

  const getSandboxTokenLink = (token: string) =>
    `${window.location.protocol}//${window.location.host}${SANDBOX}/${token}`;

  const goToGarageOrDefault = () => {
    if (isAuthenticated()) {
      if (!isAdmin()) {
        goToVehicle();
      } else {
        goToAdminUsers();
      }
    }
  };

  const goToSubscribe = () => navigate(SUBSCRIBE);
  const goToSubscribeBilling = () => navigate(SUBSCRIBE_BILLING);

  const getPublicDataRoomLink = (id: Listing["id"]) =>
    `${window.location.protocol}//${window.location.host}${DATA_ROOM}/${id}`;
  const getTransactionsListingLink = (id: Listing["id"]) =>
    `${window.location.protocol}//${window.location.host}${TRANSACTIONS}/${id}`;

  const goToTransactionsListing = (
    id: Listing["id"],
    message?: MessageOnNavigation<Listing["id"]>,
  ) => navigate(`${TRANSACTIONS}${DATA_ROOM}/${id}`, message);
  const goToTransactionsListingDataRoom = (
    id: Listing["id"],
    message?: MessageOnNavigation<Listing["id"]>,
  ) => navigate(`${TRANSACTIONS}${DATA_ROOM}/${id}${SETTINGS}`, message);
  const goToTransactionsListingNegotiation = (
    negotiationId: Negotiation["id"],
  ) => navigate(`${TRANSACTIONS_NEGOTIATIONS}/${negotiationId}`);
  const goToTransactionsListingNegotiationDataRoom = (
    negotiationId: Negotiation["id"],
  ) => navigate(`${TRANSACTIONS_NEGOTIATIONS}/${negotiationId}${DATA_ROOM}`);
  const goToTransactionsMyListings = () => navigate(TRANSACTIONS_MY_DATA_ROOMS);
  const goToTransactionsNegotiations = () =>
    navigate(TRANSACTIONS_NEGOTIATIONS);

  return {
    getCollaborationInviteLink,
    getCollectionOverviewLink,
    getDataRoomMembershipInviteLink,
    getGarageCollectionOverviewLink,
    getGarageLocationOverviewLink,
    getGarageVehicleOverviewLink,
    getLocationOverviewLink,
    getPublicDataRoomLink,
    getSandboxTokenLink,
    getTransactionsListingDataRoomPath,
    getTransactionsListingLink,
    getTransactionsListingNegotiationDataRoomPath,
    getTransactionsListingNegotiationPath,
    getTransactionsListingPath,
    getVaultFileLink,
    getVehicleOverviewLink,
    goToActivity,
    goToAdminConcierges,
    goToAdminLayoutScopes,
    goToAdminListings,
    goToAdminUsageHistory,
    goToAdminUsers,
    goToAdminVehicles,
    goToCollectionOverview,
    goToCollections,
    goToCollectorCollections,
    goToCollectorLocations,
    goToCollectorLogbook,
    goToCollectorVehicle,
    goToCookiePolicy,
    goToCurrent,
    goToDashboard,
    goToDataRoom,
    goToDataRoomGallery,
    goToDataRoomLogbook,
    goToDataRoomLogbookOverview,
    goToDataRoomMessages,
    goToDataRooms,
    goToDataRoomVault,
    goToGarageOrDefault,
    goToHelp,
    goToHome,
    goToLocationGallery,
    goToLocationOverview,
    goToLocations,
    goToLocationVehicles,
    goToLogbook,
    goToLogbookOverview,
    goToLogin,
    goToLogout,
    goToNotFound,
    goToOnboarding,
    goToOrganizationCollectors,
    goToOrganizationCollectorsGarage,
    goToOrganizationDataRooms,
    goToOrganizationDealerships,
    goToOrganizationLocation,
    goToOrganizationLocations,
    goToOrganizationRequests,
    goToOrganizationServices,
    goToOrganizationUsageHistory,
    goToOrganizationUserManagement,
    goToOrganizationVehicles,
    goToPrivacyPolicy,
    goToReminders,
    goToRequests,
    goToRequestsMessages,
    goToResetPassword,
    goToRoot,
    goToSettings,
    goToSettingsCollaboration,
    goToSettingsEncryption,
    goToSettingsExperiments,
    goToSettingsProfile,
    goToSettingsProfileAvatar,
    goToSettingsSecurity,
    goToSettingsSubscription,
    goToSettingsSubscriptionInvoices,
    goToSettingsSubscriptionPaymentMethods,
    goToSettingsTagManager,
    goToSignup,
    goToSubscribe,
    goToSubscribeBilling,
    goToTermsAndConditions,
    goToTransactionsListing,
    goToTransactionsListingDataRoom,
    goToTransactionsListingNegotiation,
    goToTransactionsListingNegotiationDataRoom,
    goToTransactionsMyListings,
    goToTransactionsNegotiations,
    goToVault,
    goToVehicle,
    goToVehicleDataRoom,
    goToVehicleDataRoomActivity,
    goToVehicleDataRoomMessage,
    goToVehicleDataRooms,
    goToVehicleGallery,
    goToVehicleLogbook,
    goToVehicleLogbookOverview,
    goToVehicleOverview,
    goToVehicleRequests,
    goToVehicleVault,
    goToWishlist,
  };
};
