import { gql, Reference, useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { Service } from "typings/Service";
import { ServiceTopics } from "typings/Topics";

const SAVE_SERVICE = gql`
  mutation SaveService($id: ID, $description: String, $title: String!) {
    saveService(
      serviceData: { id: $id, description: $description, title: $title }
    ) {
      id
      description
      title
    }
  }
`;

const schema = z.object({
  description: z.string().optional(),
  title: z.string().min(1),
});

export type ServiceFormData = z.infer<typeof schema>;

export const useServiceForm = (service?: Service) => {
  const { t } = useTranslation();
  const { enqueueMessage } = useMessaging();

  const defaultValues = {
    description: service?.description ?? "",
    title: service?.title ?? "",
  };

  const form = useForm<ServiceFormData>({
    defaultValues,
    mode: "all",
    resolver: zodResolver(schema),
  });

  const [mutate, { error, loading }] = useMutation<{
    saveService: Service;
  }>(SAVE_SERVICE);

  const save = useCallback(
    async (formData: ServiceFormData) =>
      await mutate({
        onCompleted: (data) => {
          if (data.saveService) {
            PubSub.publish(ServiceTopics.Updated, data.saveService.id);

            enqueueMessage({
              text: t(
                service?.id
                  ? "messaging.service.updated"
                  : "messaging.service.created",
              ),
              variant: "success",
            });
          }
        },

        onError: (err) =>
          form.setError("root", {
            message: t(err.message),
            type: "server",
          }),

        update: (cache, { data: mutationData }) => {
          if (!service?.id && mutationData?.saveService) {
            cache.modify<{ services: Reference[] }>({
              fields: {
                services: (existing, { toReference }) => [
                  toReference(mutationData.saveService) as Reference,
                  ...(existing ?? []),
                ],
              },
            });
          }
        },

        variables: {
          description: formData.description,
          id: service?.id,
          title: formData.title,
        },
      }),
    [service?.id],
  );

  return {
    form,
    handleSubmit: form.handleSubmit(save),
    state: { error, loading },
  };
};
