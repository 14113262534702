import { lazy } from "react";
import { Navigate, Outlet, Route } from "react-router";

import { Layout } from "components/Layout/Layout";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import {
  CONFIRM_EMAIL,
  CONFIRM_MFA,
  EARLY_ACCESS,
  LOGIN,
  NOT_FOUND,
  RESET_PASSWORD,
  SANDBOX,
  SIGNUP,
  VIEW,
} from "constants/routes";
import { useLoggedInRootPath } from "hooks/useLoggedInRootPath";
import { sandboxEnabled } from "utils/featureFlags";

const BIPage = lazy(() => import("pages/bi/BIPage"));
const ChangePasswordPage = lazy(
  () => import("pages/change-password/ChangePasswordPage"),
);
const ConfirmEmailPage = lazy(
  () => import("pages/confirm-email/ConfirmEmailPage"),
);
const ConfirmMfaPage = lazy(() => import("pages/confirm-mfa/ConfirmMfaPage"));
const KesselPage = lazy(() => import("pages/kessel/KesselPage"));
// const ListingPage = lazy(
//   () => import("pages/data-room/dataRoomId/PublicDataRoomPage"),
// );
const LoginPage = lazy(() => import("pages/login/LoginPage"));
const NotFoundPage = lazy(() => import("pages/not-found/NotFoundPage"));
const ResetPasswordPage = lazy(
  () => import("pages/reset-password/ResetPasswordPage"),
);
const SandboxPage = lazy(() => import("pages/sandbox/SandboxPage"));
const SignUpPage = lazy(() => import("pages/signup/SignUpPage"));
const ViewPage = lazy(() => import("pages/view/ViewPage"));
const PublicAppBar = lazy(() => import("components/AppBar/PublicAppBar"));
const UnsubscribePage = lazy(() => import("pages/unsubscribe/UnsubscribePage"));

const ContainedLayout = () => (
  <Layout AppBar={PublicAppBar} onlyHorizontalAppBar>
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const NoAppBarLayout = () => (
  <Layout
    AppBar={undefined}
    sx={{ alignItems: "center", display: "flex", height: "100%", padding: 2 }}
  >
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const NarrowCenteredLayout = () => (
  <Layout
    maxWidth="xs"
    sx={{ alignItems: "center", display: "flex", height: "100%", padding: 2 }}
  >
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const Root = () => {
  const loggedInRootPath = useLoggedInRootPath();

  return <Navigate to={loggedInRootPath} />;
};

const routes = [
  <Route
    element={<Navigate to={SIGNUP} />}
    key={EARLY_ACCESS}
    path={EARLY_ACCESS}
  />,

  <Route element={<ContainedLayout />} key="contained">
    <Route element={<ViewPage />} key={VIEW} path="/view/:token" />
  </Route>,

  <Route element={<NarrowCenteredLayout />} key="contained">
    {sandboxEnabled && (
      <Route path={`${SANDBOX}/:token?`}>
        <Route element={<SandboxPage />} index />
      </Route>
    )}
    <Route path={SIGNUP}>
      <Route element={<SignUpPage />} index />
    </Route>
    <Route element={<ResetPasswordPage />} path={RESET_PASSWORD} />
    <Route element={<ChangePasswordPage />} path={`${RESET_PASSWORD}/:token`} />
    <Route element={<ConfirmEmailPage />} path={`${CONFIRM_EMAIL}/:token`} />
    <Route element={<LoginPage />} path={LOGIN} />,
    <Route element={<ConfirmMfaPage />} path={CONFIRM_MFA} />,
    <Route element={<BIPage />} path="/bi" />,
    <Route element={<KesselPage />} path="/kessel" />,
    <Route element={<UnsubscribePage />} path="/unsubscribe" />
    <Route path="/signUp">
      <Route element={<SignUpPage />} index />
    </Route>
    <Route element={<ResetPasswordPage />} path="/resetPassword" />
    <Route element={<ChangePasswordPage />} path={`resetPassword/:token`} />
    <Route element={<ConfirmEmailPage />} path={`confirmEmail/:token`} />
    <Route element={<ConfirmMfaPage />} path="/confirmMfa" />
  </Route>,

  <Route element={<Root />} key="root" path="/" />,

  <Route element={<NoAppBarLayout />} key="not-found">
    <Route element={<NotFoundPage />} path="/*" />
    <Route element={<NotFoundPage />} path={`${NOT_FOUND}/*`} />
  </Route>,
];

export function PublicRoutes() {
  return <>{routes.map((route) => route)}</>;
}
